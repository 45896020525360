import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

/**
 * ライトカラーのロゴマーク
 */
export const LogoLight = () => (
<Link to="/home/" className="logo-wrap">
    <StaticImage style={{width: `150px`}} src="../../../images/logo-light.png" alt="株式会社メイン ロゴ" />
</Link>
)

/**
 * ダークカラーのロゴマーク
 */
export const LogoDark = () => (
<Link to="/home/" className="logo-wrap">
    <StaticImage style={{width: `150px`}} src="../../../images/logo-dark.png" alt="株式会社メイン ロゴ" />
</Link>
)