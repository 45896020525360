import * as React from "react"

/**
 * GoogleMapへのリンク付き住所
 */
const Address = () => (
<a href="https://g.page/main-inc?share" className="address" target="_blank" rel="noreferrer">
    &#12306;442-0889 愛知県豊川市南大通5丁目54番地
</a>
)

export default Address