/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import Header from "@src/components/templates/header/index"
import Footer from "@src/components/templates/footer/index"

import "./style.css"

const Layout = ({ children }) => {
  return (
    <div className="l-base">
      <Header />
      <main className="main l-main">{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
