import * as React from "react";

import GlobalNavigation from "@src/components/molecules/global-navigation/index";
import Copyright from "@src/components/atoms/copyright/index";
import Address from "@src/components/atoms/address/index";
import { LogoLight } from "@src/components/atoms/logo/index";

import "./style.css";

const Footer = () => {
    return(
        <footer className="footer l-footer">
            <div>
                <div className="footer-info l-padding">
                    <div className="footer-logo-wrap">
                        <LogoLight />
                    </div>
                    <p className="footer-address">
                        <Address />
                    </p>
                </div>
            </div>

            <div>
                <nav className="footer-nav l-padding">
                    <GlobalNavigation />
                </nav>
                <div className="footer-bottom l-padding">
                    <Copyright />
                </div>
            </div>
        </footer>
    )
}

export default Footer;