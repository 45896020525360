import * as React from "react";
import { CompanyInfoLink, CreationLink, HomeLink, MarketingLink, InnovationLink } from "@src/components/atoms/links/index";

import "./style.css"

/**
 * グローバルナビゲーション
 */
const GlobalNavigation = () => {
    return(
    <ul className="navigation">
        <li className="navigation-item">
            <HomeLink />
        </li>
        <li className="navigation-item">
            <CreationLink />
        </li>
        <li className="navigation-item">
            <InnovationLink />
        </li>
        <li className="navigation-item">
            <MarketingLink />
        </li>
        <li className="navigation-item">
            <CompanyInfoLink />
        </li>
    </ul>
    )
}

export default GlobalNavigation