import * as React from "react";

import "./style.css";

/**
 * コピーライト
 */
const Copyright = () => {
    return(
        <small className="copyright">{new Date().getFullYear()} &copy; MAIN inc. All rights reserved.</small>
    )
}

export default Copyright