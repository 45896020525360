import * as React from "react";
import { Link } from "gatsby";

/**
 * 各ページへのリンク
 */
const BaseLink = ({className, to, children}) => (
    <Link className={className} activeClassName="is-active" to={to}>{children}</Link>
);

export const HomeLink = (props) => (
    <BaseLink to="/home/" {...props} />
)

export const MarketingLink = (props) => (
    <BaseLink to="/marketing/" {...props} />
)

export const CreationLink = (props) => (
    <BaseLink to="/creation/" {...props} />
)

export const InnovationLink = (props) => (
    <BaseLink to="/innovation/" {...props} />
)

export const CompanyInfoLink = (props) => (
    <BaseLink to="/company-info/" {...props} />
)

export const ContactLink = (props) => (
    <BaseLink to="/contact/" {...props} />
)

HomeLink.defaultProps = {
    children: "HOME"
}

MarketingLink.defaultProps = {
    children: "運用事業部"
}

CreationLink.defaultProps = {
    children: "創造事業部"
}

InnovationLink.defaultProps = {
    children: "革命事業部"
}

CompanyInfoLink.defaultProps = {
    children: "会社概要"
}

ContactLink.defaultProps = {
    children: "お問い合わせはこちら"
}