import * as React from "react"

import Layout from "@src/components/templates/layout"
import Seo from "@src/components/seo"
import NotFound from "@src/components/pages/404/index"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <NotFound />
  </Layout>
)

export default NotFoundPage
