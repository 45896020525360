import * as React from "react";
import { StaticImage } from "gatsby-plugin-image"

import { ContactLink } from "@src/components/atoms/links/index";

import "./style.css"

/**
 * お問い合わせページへの背景付きリンクセクション
 */
const ContactInfo = () => {
    return(
        <section className="contact-info">
            <div className="gray-filter">
                <StaticImage style={{height: `100%`}} src="../../../images/contact.jpg" alt="お問い合わせ" />
                <div className="gray-filter-item contact-info-inner l-contents-padding">
                    <div>
                        <h2 className="contact-info-title">お問い合わせ</h2>
                        <p className="contact-info-text">当社のサービスに関するお問い合わせはこちらから</p>
                    </div>
                    <ContactLink className="button button-large" />
                </div>
            </div>
        </section>
    )
}

export default ContactInfo