import * as React from "react"

import GlobalNavigation from "@src/components/molecules/global-navigation/index"
import Copyright from "@src/components/atoms/copyright/index"
import Address from "@src/components/atoms/address/index"
import { LogoDark } from "@src/components/atoms/logo/index"
import { ContactLink } from "@src/components/atoms/links/index"

import "./style.css"

const initialState = {
  isOpen: false,
};

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(initialState.isOpen);

  return(
    <>
      <div className={`header-hmenu hmenu ${isOpen ? "is-open" : ""}`}>
        <div className="hmenu-inner">
          <div className="hmenu-navigation">
            <div className="hmenu-info">
              <LogoDark />
              <Address />
            </div>
            <GlobalNavigation />
          </div>
          <ContactLink className="button button-border" />
          <Copyright />
        </div>
      </div>
      <header className="header l-header">
        <div className="header-inner l-padding">

          <h1 className="header-logo">
            <LogoDark />
          </h1>

          <nav className="header-nav">
            <GlobalNavigation />
          </nav>

          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className={`header-hamburger-wrap hamburger-wrap ${isOpen ? "is-open" : ""}`} onClick={() => setIsOpen(!isOpen)} onKeyDown={() => setIsOpen(!isOpen)}>
            <span className="header-hamburger-helper hamburger-helper">MENU</span>
            <div className="header-hamburger hamburger">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}


export default Header
