import * as React from "react"

import ContactInfo from "@src/components/molecules/contact-info/index"
import { HomeLink } from "@src/components/atoms/links/index"

import "./style.css"

const NotFound = () => {
    return(
        <article className="notfound">
            <div className="notfound-inner">
                <h1 className="notfound-title">404 Not Found.</h1>
                <p className="notfound-text">お探しのページは見つかりませんでした。</p>
                <HomeLink className="button button-large">HOMEに戻る</HomeLink>
            </div>
            <ContactInfo />
        </article>
    )
}

export default NotFound